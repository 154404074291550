<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import useFilterStore from '~/stores/filter'
import useAuthStore from '~/stores/auth'
import { GradeType } from '~/models/GradeType'
import { useHeaderQuery } from '~/composables/useHeaderQuery'
import { useContentHelper } from '~/composables/useContentHelper'
import { useContentFilter } from '~/composables/useContentFilter'
import LowerSecondaryHeader from '~/components/subject/gradetypes/LowerSecondaryHeader.vue'
import LowerPrimaryHeader from '~/components/subject/gradetypes/LowerPrimaryHeader.vue'
import ContentFilteredAwayCallout from '~/components/ResourceFinder/ContentFilteredAwayCallout.vue'

defineProps<{ subjectCode: SubjectCode }>()

const { selectedGradeType } = storeToRefs(useAuthStore())
const { isLoading: filterIsLoading } = storeToRefs(useFilterStore())
const { filteredContents, filteredProductPackages, contents } = useContentFilter()
const { isLoading, refetch, isRefetching } = useHeaderQuery()
const { isPackage } = useContentHelper()

const contentItems = computed(() => filteredContents.value.filter((content) => !isPackage(content)))
const combinedFiltered = computed(() => [...contentItems.value, ...filteredProductPackages.value])
const combinedContents = computed(() => [...contents.value, ...filteredProductPackages.value])

const headerProps = computed(() => ({
  contents: contentItems.value,
  packages: filteredProductPackages.value,
  isRefetching: isRefetching.value,
  isLoading: isLoading.value,
}))

watch(filteredProductPackages, () => refetch())
</script>

<template>
  <LowerPrimaryHeader
    v-if="selectedGradeType === GradeType.LowerPrimary && combinedFiltered.length > 0"
    v-bind="headerProps"
  />
  <LowerSecondaryHeader
    v-if="selectedGradeType === GradeType.LowerSecondary && combinedFiltered.length > 0"
    v-bind="headerProps"
  />

  <div
    v-if="!combinedFiltered.length && combinedContents.length > 0 && !filterIsLoading"
    class="mx-auto flex max-w-screen-au flex-col gap-4 px-4 pb-16 pt-36 sm:px-8"
  >
    <ContentFilteredAwayCallout :contents="combinedContents" />
  </div>
</template>
