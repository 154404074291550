<script lang="ts" setup>
import type { ColorPair } from '~/models/Presentation/BasePage'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, onMounted } from 'vue'
import { PendoFeature } from '~/models/Pendo'
import usePendo from '~/composables/usePendo'
import FullscreenButton from '~/components/utils/FullscreenButton.vue'
import CloseButton from '~/components/utils/CloseButton.vue'

const { viewsWithTrackingEnabled } = usePendo()

const props = withDefaults(defineProps<{
  variant?: 'default' | 'white' | 'colorPair'
  item?: BaseItem
  colorPair?: ColorPair
}>(), {
  variant: 'default',
})

const defaultConfig = {
  fullscreenStyles: {},
  closeStyles: {},
  closeClasses: [],
  closeVariant: 'border',
  closeShape: 'round',
  closeIconLeft: 'xmark',
  closeSize: 'large',
  closeIsFixed: true,
}

const whiteConfig = {
  fullscreenStyles: {
    background: 'rgb(var(--au-white))',
    color: 'var(--ks-secondary)',
    border: '1px solid var(--ks-secondary)',
  },
  closeStyles: {},
  closeClasses: ['!border', '!border-solid', '!bg-white'],
  closeVariant: 'secondary',
  closeShape: 'round',
  closeIconLeft: 'xmark',
  closeIsFixed: false,
}

const colorPairConfig = computed(() => ({
    fullscreenStyles: {
      background: props.colorPair?.background.rgb,
      color: props.colorPair?.text.rgb,
      border: `1.5px solid ${props.colorPair?.text.rgb}`,
    },
    closeStyles: {
      background: props.colorPair?.background.rgb,
      color: props.colorPair?.text.rgb,
      border: `1.5px solid ${props.colorPair?.text.rgb}`
    },
    closeClasses: [],
    closeVariant: 'border',
    closeShape: 'round',
    closeIconLeft: 'xmark',
    closeSize: 'large',
    closeIsFixed: false,
}))

const buttonConfig = computed(() => {
  switch (props.variant) {
    case 'white':
      return whiteConfig
    case ('colorPair'):
      if (!props.colorPair) return defaultConfig
      return colorPairConfig.value
    default:
      return defaultConfig
  }
})

let fullscreenPendo = ''
let closePendo  = ''

onMounted(() => {
  if(props.item?.contentTypeIdentifier && viewsWithTrackingEnabled.includes(props.item?.contentTypeIdentifier)) {
    fullscreenPendo = PendoFeature.Presentation.Fullscreen
    closePendo = PendoFeature.Presentation.Exit
  }
})
</script>

<template>
  <div
    v-if="buttonConfig"
    class="pointer-events-none absolute inset-x-0 top-0 flex flex-col"
  >
    <div class="pointer-events-auto flex items-center gap-4 self-end p-4">
      <FullscreenButton
        :style="buttonConfig.fullscreenStyles"
        :data-pendo="fullscreenPendo"
      />
      <CloseButton
        :style="buttonConfig.closeStyles"
        :classes="buttonConfig.closeClasses"
        :variant="buttonConfig.closeVariant"
        :shape="buttonConfig.closeShape"
        :icon-left="buttonConfig.closeIconLeft"
        :item="props.item"
        :is-fixed="buttonConfig.closeIsFixed"
        :data-pendo="closePendo"
      />
    </div>
  </div>
</template>
