<script setup lang="ts">
import type { SortField } from '~/models/Content/Sort'
import { useI18n } from 'vue-i18n'
import { KsButton, KsPopover } from '@aschehoug/kloss'

defineProps<{
  field: SortField
  disabled: boolean
}>()

const { t } = useI18n()
const fields: SortField[] = ['score', 'modified']

const emits = defineEmits(['sortField'])

const iconMap = (field: SortField) => ({
  score: 'waveform',
  modified: 'clock',
}[field])
</script>
<template>
  <KsPopover
    :title="t(`search.sortField.${field}`)"
    direction="down left"
    :autoposition="false"
  >
    <template #toggle>
      <KsButton
        :icon-left="iconMap(field)"
        variant="border"
        size="small"
        :disabled="disabled"
      >
        {{ t(`search.sortField.${field}`) }}
      </ksbutton>
    </template>
    <template #content>
      <slot name="menu">
        <KsButton
          v-for="sortField in fields"
          :key="sortField"
          class="my-2"
          :icon-left="iconMap(sortField)"
          variant="tertiary"
          size="small"
          stretch
          :disabled="sortField === field"
          @click.prevent="emits('sortField', sortField)"
        >
          {{ t(`search.sortField.${sortField}`) }}
        </KsButton>
      </slot>
    </template>
  </KsPopover>
</template>
