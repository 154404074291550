import type { Criterion, Options, Query } from '~/models/Search'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { debounce } from '~/utils/functionUtils'
import { useAuthStore } from '~/stores/auth'
import { restClient } from '~/api/client/restClient'

function createKeyValueParams(object: Record<string, string>, field: string): string {
  const fieldsParam: string[] = []
  Object.keys(object).forEach(function(key, index) {
    fieldsParam.push(`${field}[${key}]=${encodeURIComponent(object[key])}`)
  })
  return fieldsParam.join('&')
}

function buildQuery(query: Query, criterion: Criterion,  limit: number, offset: number) {
  let queryString = `fullText=${query.text || '*'}`
  if (query.fuzziness) {
    queryString += `&fuzziness=${query.fuzziness}`
  }
  if (query.spellcheck) {
    queryString += '&spellcheck=1'
  }
  if (criterion.contentTypeCriterion?.length) {
    queryString += `&${criterion.contentTypeCriterion.map((value) => `contentTypes[]=${encodeURIComponent(value)}`).join('&')}`
  }
  if (criterion.subtreeCriterion?.length) {
    queryString += `&${criterion.subtreeCriterion.map((value) => `subtrees[]=${encodeURIComponent(value)}`).join('&')}`
  }
  if (criterion.labelFieldCriterion?.length) {
    queryString += `&${criterion.labelFieldCriterion.map((value) => `label[]=${encodeURIComponent(value)}`).join('&')}`
  }
  if (criterion.activityFieldCriterion?.length) {
    queryString += `&${criterion.activityFieldCriterion.map((value) => `activity[]=${encodeURIComponent(value)}`).join('&')}`
  }
  if (criterion.gradeFieldCriterion?.length) {
    queryString += `&${criterion.gradeFieldCriterion.map((value) => `grade[]=${encodeURIComponent(value)}`).join('&')}`
  }
  if (criterion.contentIdCriterion?.length) {
    queryString += `&${criterion.contentIdCriterion.map((value) => `contentId[]=${encodeURIComponent(value)}`).join('&')}`
  }
  if (criterion.parentLocationIdCriterion?.length) {
    queryString += `&${criterion.parentLocationIdCriterion.map((value) => `parentLocationId[]=${encodeURIComponent(value)}`).join('&')}`
  }
  if (criterion.locationIdCriterion?.length) {
    queryString += `&${criterion.locationIdCriterion.map((value) => `locationId[]=${encodeURIComponent(value)}`).join('&')}`
  }
  if (criterion.accessStateCriterion?.length) {
    queryString += `&${criterion.accessStateCriterion.map((value) => `access[]=${encodeURIComponent(value)}`).join('&')}`
  }
  if (criterion.gradeOperator) {
    queryString += `&gradeOperator=${criterion.gradeOperator}`
  }
  if (criterion.activityOperator) {
    queryString += `&activityOperator=${criterion.activityOperator}`
  }
  if (criterion.aggregationCriterion?.length) {
    queryString += `&${criterion.aggregationCriterion.map((value) => `aggregation[]=${encodeURIComponent(value)}`).join('&')}`
  }
  if (criterion?.sortField) {
    queryString += `&sortField=${criterion.sortField.toLowerCase()}`
  }
  if (criterion?.sortOrder) {
    queryString += `&sortOrder=${{ 'asc': 'ascending', 'desc': 'descending' }[criterion.sortOrder]}`
  }
  if (query.boost) {
    queryString += `&${createKeyValueParams(query.boost, 'boost')}`
  }
  if (query.fields?.length) {
    queryString += `&fields=${query.fields.join(',')}`
  }
  return `${queryString}&mainLocation=${criterion.mainLocationCriterion ? 1 : 0}&limit=${limit}&offset=${offset}`
}

export function useSearch<T>(path: string, {
  transformData = (data) => data,
  debounceDuration = 1_000,
}: Options) {
  if (!path) {
    throw new Error('"path" option is required')
  }

  const { isTeacher } = storeToRefs(useAuthStore())

  const results = ref<T>()
  const isLoading = ref(false)
  const hasFailed = ref(false)

  async function fetchResults(query: Query, criterion: Criterion = {}, limit: number = 10, offset: number = 0): Promise<void> {
    isLoading.value = true
    hasFailed.value = false
    try {
      const response = (await restClient.get(`${path}?${buildQuery(query, criterion, limit, offset)}${isTeacher.value ? '&type=json' : ''}`)).data
      results.value = transformData(response)
    } catch (error) {
      hasFailed.value = true
      throw error
    } finally {
      isLoading.value = false
    }
  }

  return {
    isLoading,
    hasFailed,
    results,
    fetchResults,
    debounceResults: debounce(fetchResults, debounceDuration),
  }
}

export default useSearch
