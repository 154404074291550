<script setup lang="ts">
import type { ContentFeatured } from '~/models/Content/ContentFeatured'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsIcon } from '@aschehoug/kloss'
import useAuthStore from '~/stores/auth'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useSectionPath from '~/composables/useSectionPath'
import { useLottie } from '~/composables/useLottie'
import useContentApi from '~/api/contentApi'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'

const { buildResourceUrl } = useUrlBuilder()
const { userRelevantGrades } = storeToRefs(useAuthStore())
const { findContents } = useContentApi()
const { lottieJSON, getLottie } = useLottie()
const { featuredPath } = useSectionPath()

const autoplay = !matchMedia('(prefers-reduced-motion)').matches
const url = computed(() => data?.value?.relation ? buildResourceUrl(data.value.relation) : '')

const loadRelation = async (content: ContentFeatured) => {
  if (!content.relation?.destinationContentId) return []
  return (await findContents<BaseItem>({
    contentIdCriterion: [Number(content.relation.destinationContentId)],
    mainLocationCriterion: true,
  }, 1))[0]
}

const loadLottie = (content: ContentFeatured) => {
  if (Number(content.lottie?.destinationContentId) === 0) return null
  return getLottie(Number(content.lottie.destinationContentId))
}

const { data } = useQuery({
  staleTime: Infinity,
  queryKey: ['lower-primary-featured-card', featuredPath],
  queryFn: async () => {
    const content = (await findContents<ContentFeatured>({
      subtreeCriterion: [featuredPath],
      gradeFieldCriterion: userRelevantGrades.value,
      contentTypeCriterion: [ContentType.Featured],
    }, 1))[0]

    if (!content) return {}

    const [relation] = await Promise.all([
      loadRelation(content),
      loadLottie(content)
    ])

    return { content, relation, lottieJSON: lottieJSON.value }
  }
})
</script>

<template>
  <div
    v-if="data?.content"
    class="group pointer-events-auto my-12 w-[350px] rotate-1 self-center rounded bg-yellow-30 transition-all duration-300 hover:-rotate-[6deg] lg:absolute lg:bottom-0 lg:left-0 lg:z-10 lg:my-0"
  >
    <div class="relative flex max-w-[350px] -rotate-6 flex-col gap-2 rounded bg-yellow-10 px-6 py-4 text-black shadow transition-all duration-300 group-hover:rotate-[6deg]">
      <span
        v-if="data?.relation"
        class="text-sm font-semibold uppercase tracking-wider"
        v-text="data.relation.title"
      />
      <span class="font-calistoga !leading-8 fluid-text-lg">
        <LinkOrAnchor
          class="line-clamp-3 outline-0 before:absolute before:inset-0 before:rounded before:transition before:hover:shadow-xl before:focus-visible:ring"
          :link="url"
        >
          {{ data.content.title }}
        </LinkOrAnchor>
      </span>
      <RichTextRenderer
        class="text-gray-50"
        :text="data.content.description"
      />
      <KsIcon
        id="arrow-long-right"
        class="transition-all duration-500 group-hover:ml-6"
        :scale="1.5"
      />
    </div>
  </div>
  <LottieAnimation
    v-if="data?.lottieJSON"
    :animation-data="data?.lottieJSON"
    :auto-play="autoplay"
    width="620px"
    height="620px"
    :speed="1.2"
    class="hidden md:block lg:absolute lg:bottom-36 lg:right-0 lg:max-w-[50dvw]"
  />
</template>
