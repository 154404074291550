<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { KsButton } from '@aschehoug/kloss'
import { PendoFeature } from '~/models/Pendo'
import SettingsDrawer from '~/components/settings/SettingsDrawer.vue'

const emit = defineEmits(['close'])

const { t } = useI18n()
const drawerOpen = ref(false)

const close = () => {
  drawerOpen.value = false
  emit('close')
}
</script>

<template>
  <div class="block sm:hidden">
    <KsButton
      stretch
      icon-left="cog"
      size="large"
      :data-pendo="PendoFeature.Navigation.Settings"
      @click.prevent="drawerOpen = !drawerOpen"
    >
      {{ t('settings.title') }}
    </KsButton>
  </div>
  <div class="hidden sm:block">
    <KsButton
      v-ks-tooltip="t('settings.title')"
      variant="secondary"
      shape="round"
      size="large"
      icon-left="cog"
      :data-pendo="PendoFeature.Navigation.Settings"
      @click.prevent="drawerOpen = !drawerOpen"
    />
  </div>
  <SettingsDrawer
    :open="drawerOpen"
    @close="close"
  />
</template>
