export enum ColorName {
  White = 'white',
  Black = 'black',
  Yellow5 = 'yellow-5',
  Yellow10 = 'yellow-10',
  Yellow20 = 'yellow-20',
  Yellow30 = 'yellow-30',
  Yellow40 = 'yellow-40',
  Yellow50 = 'yellow-50',
  Yellow60 = 'yellow-60',
  YellowPop = 'yellow-pop',
  Orange5 = 'orange-5',
  Orange10 = 'orange-10',
  Orange20 = 'orange-20',
  Orange30 = 'orange-30',
  Orange40 = 'orange-40',
  Orange50 = 'orange-50',
  Orange60 = 'orange-60',
  OrangePop = 'orange-pop',
  Coral5 = 'coral-5',
  Coral10 = 'coral-10',
  Coral20 = 'coral-20',
  Coral30 = 'coral-30',
  Coral40 = 'coral-40',
  Coral50 = 'coral-50',
  Coral60 = 'coral-60',
  CoralPop = 'coral-pop',
  Violet5 = 'violet-5',
  Violet10 = 'violet-10',
  Violet20 = 'violet-20',
  Violet30 = 'violet-30',
  Violet40 = 'violet-40',
  Violet50 = 'violet-50',
  Violet60 = 'violet-60',
  VioletPop = 'violet-pop',
  Purple5 = 'purple-5',
  Purple10 = 'purple-10',
  Purple20 = 'purple-20',
  Purple30 = 'purple-30',
  Purple40 = 'purple-40',
  Purple50 = 'purple-50',
  Purple60 = 'purple-60',
  PurplePop = 'purple-pop',
  Blue5 = 'blue-5',
  Blue10 = 'blue-10',
  Blue20 = 'blue-20',
  Blue30 = 'blue-30',
  Blue40 = 'blue-40',
  Blue50 = 'blue-50',
  Blue60 = 'blue-60',
  BluePop = 'blue-pop',
  SeaGreen5 = 'seagreen-5',
  SeaGreen10 = 'seagreen-10',
  SeaGreen20 = 'seagreen-20',
  SeaGreen30 = 'seagreen-30',
  SeaGreen40 = 'seagreen-40',
  SeaGreen50 = 'seagreen-50',
  SeaGreen60 = 'seagreen-60',
  SeaGreenPop = 'seagreen-pop',
  Green5 = 'green-5',
  Green10 = 'green-10',
  Green20 = 'green-20',
  Green30 = 'green-30',
  Green40 = 'green-40',
  Green50 = 'green-50',
  Green60 = 'green-60',
  GreenPop = 'green-pop',
  Gray5 = 'gray-5',
  Gray10 = 'gray-10',
  Gray20 = 'gray-20',
  Gray30 = 'gray-30',
  Gray40 = 'gray-40',
  Gray50 = 'gray-50',
  Gray60 = 'gray-60',
}

export interface ColorTheme {
  text: ColorName
  primary: ColorName
  secondary: ColorName
  dark: ColorName
  highlight: ColorName
  accent: ColorName
  accentSecondary: ColorName
  accentHighlight: ColorName
  isDark: boolean
}

export type Color = {
  name: string
  rgb: string
  var: string
}
