import type { GradeCode } from '~/models/Grade'
import { gradesSorted, sortByGradeIndex } from '~/utils/gradeSorter'
import { i18n } from '~/translations'

export default () => {
  const createCombinedBlocks = (grades: GradeCode[]) => {
    const blocks: GradeCode[][] = []
    let block: GradeCode[] = []
    grades.forEach((grade) => {
      const orderedIndex = gradesSorted.indexOf(grade)
      const nextOrdered = gradesSorted[orderedIndex + 1]
      const index = grades.indexOf(grade)
      const next = grades[index + 1]
      block.push(grade)
      if (!next || nextOrdered !== next) {
        blocks.push(block)
        block = []
      }
    })

    return blocks
  }

  const combineGradeString = (grades: GradeCode[]) => {
    const { t } = i18n.global
    if (!grades.length) return ''
    if (grades.length === 1) return t(`metadata.gradesPunctuated.${grades[0]}`)

    const sortedGrades = grades.sort(sortByGradeIndex)

    if (grades.every((grade) => grade.includes('aarstrinn'))) {
      const first = t(`metadata.gradesShort.${sortedGrades[0]}`)
      const last = t(`metadata.gradesPunctuated.${sortedGrades[sortedGrades.length - 1]}`)
      return `${first}.–${last}`
    }

    const first = t(`metadata.gradesPunctuated.${sortedGrades[0]}`)
    const last = t(`metadata.gradesPunctuated.${sortedGrades[sortedGrades.length - 1]}`)
    return `${first}–${last}`
  }

  const createGradeString = (grades: GradeCode[]) => createCombinedBlocks(grades.sort(sortByGradeIndex))
    .map((levelBlock) => combineGradeString(levelBlock))
    .join(', ')

  return {
    createGradeString,
  }
}
