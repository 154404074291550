<script lang="ts" setup>
import type { ContentSectionBox } from '~/models/Content/ContentSectionBox'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsCarousel, KsCarouselItem, KsIcon } from '@aschehoug/kloss'
import arrayUtils from '~/utils/arrayUtils'
import useAuthStore from '~/stores/auth'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useColors from '~/composables/colors'
import useContentApi from '~/api/contentApi'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'

const { section } = defineProps<{ section: ContentSectionBox }>()

const backgroundElement = ref()

const { backgroundStyle, needsDarkText } = useColors({
  identifier: section.locationId!,
  backgroundColor: section.color[0],
  backgroundFormatter: (color) => `linear-gradient(180deg, ${color}, ${color}E6)`,
  elementRef: backgroundElement,
})

const textStyle = computed(() => needsDarkText.value ? 'text-black' : 'text-white')

const { t } = useI18n()
const { buildResourceUrl } = useUrlBuilder()
const { findContents } = useContentApi()
const { truthy, empty, intersect } = arrayUtils()
const { userRelevantGrades } = storeToRefs(useAuthStore())

const filteredFeatured = computed(() => (data?.value?.featured ?? [])
  .filter(({ grades }) => empty(grades) || !empty(intersect(grades, userRelevantGrades.value)))
)

const contentUrl = computed(() => data && data.value.content
  ? buildResourceUrl(data.value.content)
  : '')

const loadedSectionFeatured = async () => {
  if (!section.featured?.destinationContentIds?.length) {
    return []
  }
  const relations = await findContents({
    contentIdCriterion: section.featured.destinationContentIds,
    mainLocationCriterion: true,
  }, section.featured.destinationContentIds.length)
  return section.featured.destinationContentIds
    .map((id) => relations.find(({ contentId }) => contentId === id))
    .filter(truthy<BaseItem>)
}

const loadPackageContent = async () => {
  if (!section.package?.destinationContentId) {
    return null
  }
  return (await findContents({
    contentIdCriterion: [Number(section.package.destinationContentId)],
    mainLocationCriterion: true,
  }, 1))[0]
}

const loadPackageSubitems = async (content: BaseItem) => await findContents({
  parentLocationIdCriterion: [content.mainLocationId],
  sortField: content.sortField,
  sortOrder: content.sortOrder,
  contentTypeCriterion: [
    ContentType.ProductArticle,
    ContentType.ProductHeader,
    ContentType.LearningPath,
    ContentType.ProductPackage,
    ContentType.ProductPart,
  ],
})

const { data } = useQuery({
  staleTime: Infinity,
  queryKey: ['section-box', section.contentId],
  queryFn: async () => {
    const content = Number(section.package?.destinationContentId) > 0
      ? await loadPackageContent()
      : null

    const featured = content && Number(section.featured?.destinationContentIds?.length) === 0
      ? await loadPackageSubitems(content)
      : await loadedSectionFeatured()

    return { content, featured }
  }
})
</script>

<template>
  <section
    v-if="data?.content || filteredFeatured.length"
    class="mx-auto w-full overflow-hidden sm:max-w-screen-au sm:px-8"
  >
    <div
      ref="backgroundElement"
      class="px-4 py-8 sm:rounded-2xl sm:p-14"
      :style="backgroundStyle"
      :class="textStyle"
    >
      <h2
        class="mb-2 text-3xl font-bold sm:text-4xl"
        v-text="section.title"
      />
      <p
        v-if="section.description"
        class="max-w-prose text-base"
        v-html="section.description"
      />
      <LinkOrAnchor
        v-if="data.content"
        class="mt-4 inline-flex items-center gap-2 font-medium hover:underline focus-visible:ring"
        :link="contentUrl"
      >
        {{ t('links.seeAll') }}
        <KsIcon icon="arrow-right" />
      </LinkOrAnchor>
      <KsCarousel v-if="filteredFeatured.length">
        <KsCarouselItem
          v-for="item in filteredFeatured"
          :key="item.locationId"
        >
          <slot
            name="resource"
            :resource-item="item"
          />
        </KsCarouselItem>
      </KsCarousel>
    </div>
  </section>
</template>
