<script setup lang="ts">
import type { ContentSubjectHeader } from '~/models/Content/ContentSubjectHeader'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { setTitle } from '~/utils/dom'
import { waitFor } from '~/utils/asyncUtils'
import useSubjectsStore from '~/stores/subjects'
import { useSectionStore } from '~/stores/section'
import useProductStore from '~/stores/product'
import useFilterSettingsStore from '~/stores/filterSettings'
import useFilterStore from '~/stores/filter'
import { useAuthStore } from '~/stores/auth'
import { ContentType } from '~/models/Content/ContentType'
import useQueryFilter from '~/composables/useQueryFilter'
import useProductMapper from '~/composables/useProductMapper'
import useContentApi from '~/api/contentApi'
import ProductBanner from '~/components/banners/ProductBanner.vue'
import TeacherResources from '~/components/ResourceFinder/TeacherResources.vue'
import ResourceFinder from '~/components/ResourceFinder/ResourceFinder.vue'
import SectionRenderer from '~/components/ResourceEngine/SectionRenderer.vue'

const { setCurrentSubject } = useSubjectsStore()
const { setFilterOptions, resetFilterOptions } = useFilterSettingsStore()
const { findContents } = useContentApi()
const { isTeacher } = storeToRefs(useAuthStore())
const { mapProduct } = useProductMapper()

const productStore = useProductStore()
const { hasLoaded: hasLoadedProducts } = storeToRefs(productStore)
const { addProduct, hasProduct } = productStore

const sectionStore = useSectionStore()
const { loadSections } = sectionStore
const { userSections, sections } = storeToRefs(sectionStore)

const filterStore = useFilterStore()
const { setSelectedAddonLocationId, filterWatchers, $reset } = filterStore
const { selectedHeaders, selectedGrade } = storeToRefs(filterStore)

const watchers = filterWatchers()
onMounted(() => useQueryFilter())

onBeforeUnmount(() => {
  watchers.stopFilterWatchers()
  $reset()
})

const props = defineProps<{ locationId: number }>()

const product = ref<ContentProduct>()
const productData = ref<ContentSubjectHeader[]>([])
const isLoading = ref(false)

const relevantBanner = computed(() => productData.value
  .find((header) => header.grades.includes(selectedGrade.value)) ?? productData.value[0])

const banner = computed( () => ({
  title: product.value?.title,
  text: product.value?.description,
  image: relevantBanner.value?.image,
}))

const loadProduct = async (locationId: number) => {
  try {
    isLoading.value = true
    const contentValue = (await findContents<ContentProduct>({
      locationIdCriterion: [locationId],
      contentTypeCriterion: [ContentType.Product],
    }, 1))[0]
    if (!contentValue) throw Error(`Location ${locationId} not found`)
    product.value = contentValue
    await waitFor(() => hasLoadedProducts.value, 1000)
    if (!hasProduct(product.value.ean)) {
      addProduct(await mapProduct(product.value))
    }
  } catch (error) {
    product.value = undefined
    sections.value = {}
    throw error
  } finally {
    isLoading.value = false
  }
}

const loadContent = (product?: ContentProduct) => {
  if (!product) return
  setCurrentSubject(undefined)
  setTitle(product.title)
  setSelectedAddonLocationId(product.locationId)
  loadProductData(product.pathString)
  loadSections(product.pathString)
}

const loadProductData = async (pathString: string): Promise<void> => {
  productData.value = await findContents<ContentSubjectHeader>({
    subtreeCriterion: [pathString],
    contentTypeCriterion: [ContentType.SubjectHeader],
    sortField: 'priority',
    sortOrder: 'desc',
  })
}

onBeforeMount(async () => {
  setFilterOptions({ subject: false, grade: true, header: true })
  await loadProduct(props.locationId).then(() => loadContent(product.value))
})

onBeforeUnmount(() => {
  productData.value = []
  const { $reset } = useFilterStore()
  setSelectedAddonLocationId(undefined)
  resetFilterOptions()
  $reset()
})

watch(props, async () => {
  await loadProduct(props.locationId).then(() => loadContent(product.value))
})
</script>

<template>
  <ProductBanner
    :title="banner.title"
    :image="banner.image"
    :text="banner.text"
  />
  <ResourceFinder
    show-package-intro
    :enable-subject-filter="false"
  />
  <TeacherResources
    v-if="isTeacher && hasLoadedProducts && !selectedHeaders.length"
    :location-id="product?.locationId"
  />
  <SectionRenderer
    v-if="userSections.length && !selectedHeaders.length"
    :items="userSections"
  />
</template>
