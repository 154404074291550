<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { Product, SupportProductName } from '~/models/Product'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import { isAlwaysIncluded, sortBySubjectIndex } from '~/utils/subjectSorter'
import arrayUtils from '~/utils/arrayUtils'
import useProductStore from '~/stores/product'
import router from '~/router'
import useText from '~/composables/useText'
import useSlugify from '~/composables/useSlugify'
import SupportProductModal from '~/components/home/SupportProductModal.vue'
import DefaultIllustration from '~/components/home/DefaultIllustration.vue'
import DefaultGreetings from '~/components/home/DefaultGreetings.vue'

const { t } = useI18n()
const { slugify } = useSlugify()
const { capitalize } = useText()
const { unique } = arrayUtils()

const {
  filteredSubjectCodes,
  filteredAddonProducts,
  filteredSupportProducts,
  filteredSupportProductNames,
  selectedSupportProductName,
} = storeToRefs(useProductStore())

const withExtraSubjects = computed(() => filteredSubjectCodes.value.length > 0
  ? [...filteredSubjectCodes.value, ...isAlwaysIncluded].filter(unique<SubjectCode>).sort(sortBySubjectIndex)
  : []
)

const mapSupportIcon = (name: SupportProductName) => ({
  'bokstøtte': 'book',
  'lydstøtte': 'headphones',
}[name])

const buildProductUrl = (product: Product) => router.resolve({
  name: 'product',
  params: {
    locationId: product.aunivers.locationId,
    slug: slugify(product.aunivers.name),
  },
}).href
</script>

<template>
  <header class="relative mb-12 flex-col justify-center bg-gradient-to-tr from-seagreen-40 to-custom-green to-60% text-white">
    <div class="relative z-20 mx-auto h-full w-full max-w-screen-au px-4 pb-10 pt-40 transition-all xs:pb-14 sm:px-8 sm:pt-28">
      <h1
        class="sr-only"
        v-text="t('home.title')"
      />
      <DefaultGreetings />
      <p
        v-if="withExtraSubjects.length"
        class="mb-2 mt-4 font-medium"
        v-text="t('home.yourSubjects')"
      />
      <ul
        v-if="withExtraSubjects.length"
        class="
          relative z-10 -mx-4 flex h-fit gap-3 overflow-x-scroll
          px-4 pb-4 xs:flex-wrap xs:overflow-x-visible xs:pb-0 md:max-w-[70%]
        "
      >
        <li
          v-for="subjectCode of withExtraSubjects"
          :key="subjectCode"
        >
          <RouterLink
            :to="{ name: 'subject', params: { subject: subjectCode.toLowerCase() } }"
            class="inline-flex rounded-full transition focus-visible:ring"
          >
            <KsButton
              variant="secondary"
              type="label"
              shape="rounded"
              style="--ks-input: white"
            >
              {{ t(`metadata.subjects.${subjectCode}`) }}
            </KsButton>
          </RouterLink>
        </li>
      </ul>
      <p
        v-if="filteredAddonProducts.length || filteredSupportProducts.length"
        class="mb-2 mt-6 font-medium"
        v-text="t('home.yourAddons')"
      />
      <ul
        v-if="filteredAddonProducts.length || filteredSupportProducts.length"
        class="relative z-10 -mx-4 flex h-fit gap-3 overflow-x-scroll px-4 pb-4 xs:flex-wrap xs:overflow-x-visible xs:pb-0 md:max-w-[70%]"
      >
        <li
          v-for="addon in filteredAddonProducts"
          :key="addon.ean"
        >
          <RouterLink
            :to="{ path: buildProductUrl(addon) }"
            class="inline-flex rounded-full transition focus-visible:ring"
          >
            <KsButton
              variant="secondary"
              type="label"
              shape="rounded"
              style="--ks-input: white"
            >
              {{ addon.aunivers.name }}
            </KsButton>
          </RouterLink>
        </li>
        <li
          v-for="name of filteredSupportProductNames"
          :key="name"
        >
          <KsButton
            variant="secondary"
            type="label"
            shape="rounded"
            style="--ks-input: white"
            :icon-left="mapSupportIcon(name)"
            @click="selectedSupportProductName = name"
          >
            {{ capitalize(name) }}
          </KsButton>

          <SupportProductModal
            :name="name"
            :products="filteredSupportProducts"
            :subjects="filteredSubjectCodes"
            @close="selectedSupportProductName = undefined"
          />
        </li>
      </ul>
      <DefaultIllustration />
    </div>
  </header>
</template>
