<script setup lang="ts">
import { KsButton, KsIcon, KsInput } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

defineProps<{
  isLoading: boolean
  subjects: string[]
  selectedSubjects: string[]
}>()

const emit = defineEmits([
  'addCriterion',
])

const { t } = useI18n()
const showSubjectFilters = ref(false)
</script>

<template>
  <div class="space-y-8">
    <div class="space-y-4">
      <button
        class="flex w-full justify-between border-b border-b-white pb-1 transition hover:border-b-gray-20 focus-visible:ring"
        :aria-label="showSubjectFilters ? t('search.filters.hideSubjectFilter') : t('search.filters.showSubjectFilter')"
        @click="showSubjectFilters = !showSubjectFilters"
      >
        <span
          class="text-sm font-medium uppercase tracking-wider text-gray-40"
          v-text="t('filters.subject')"
        />
        <KsIcon
          icon="chevron-down"
          class="text-gray-40 transition"
          :class="{'rotate-180': showSubjectFilters}"
        />
      </button>
      <ul
        v-if="showSubjectFilters"
        class="mt-2 flex flex-col gap-2"
      >
        <li
          v-for="(subject, i) in subjects"
          :key="i"
        >
          <KsButton
            variant="border"
            type="label"
            stretch
          >
            <KsInput
              type="checkbox"
              :checked="selectedSubjects.includes(subject)"
              @click="emit('addCriterion', subject)"
            />
            <span v-text="t(`metadata.subjects.${subject}`)" />
          </KsButton>
        </li>
      </ul>
    </div>
  </div>
</template>
