<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useAppColor } from '~/composables/useAppColor'
import AuLogo from '~/components/utils/AuLogo.vue'

const { themeColor } = useAppColor()
const route = useRoute()

const textColor = computed(() => {
  const name = themeColor.value?.name || ''
  const matches = /-(5|10|20)$/.test(name)
  return (matches || route.name === 'search') ? 'text-black' : 'text-white'
})
</script>

<template>
  <AuLogo
    class="h-8 w-auto"
    :class="textColor"
  />
</template>
