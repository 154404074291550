<script setup lang="ts">
import { KsButton, KsCallout, KsDrawer, KsInput } from '@aschehoug/kloss'
import { computed, inject, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '~/stores/auth'
import { storeToRefs } from 'pinia'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import { SettingsBasic, SettingsGroups, SettingsLicenses, SettingsYearPlans } from '.'
import router from '~/router'
import { GradeType } from '~/models/GradeType'
import GradeTypeButton from '~/components/settings/GradeTypeButton.vue'
import { PreferredLanguage } from '~/models/User/PreferredLanguage'
import useArrayUtils from '~/utils/arrayUtils'
import { findRelevantGradeType } from '~/utils/gradeSorter'

const props = defineProps<{
  open: boolean
}>()

interface SettingType {
  [key: string]: boolean
}

const settings = reactive<SettingType>({
  groups: false,
  licenses: false,
  plans: false,
})

const { t } = useI18n()
const emit = defineEmits(['close'])
const gradeTypes = GradeType

const ksToast = <Toast>inject('ksToast')
const authStore = useAuthStore()
const { isPreferredLanguage, setPreferredLanguage, setGradeType, updateUser } = authStore
const { isTeacher, hasOrganization, userGrades, userPreferredLanguage, userReadOnlyGrades, sessionUserInfo, isLoading } = storeToRefs(authStore)

let savedPreferredLanguage = ''

const digitalBooksUrl = computed(() => <string>import.meta.env.VITE_UNIBOK_SITEURL)
const oldContentUrl = computed(() => `${import.meta.env.VITE_AUNIVERS_SITEURL}/fagpakker/nedlastinger/innhold-fra-lokus-lk-06`)

const readOnlyGradesSpansAcrossgradeTypes = computed(() => {
  const { unique, truthy } = useArrayUtils()
  return userReadOnlyGrades.value.length > 0
    ? userReadOnlyGrades.value.map(findRelevantGradeType).filter(truthy<GradeType>).filter(unique<GradeType>).length > 1
    : false
})

const saveLanguage = () => {
  if (userPreferredLanguage.value !== savedPreferredLanguage) {
    updateUser(sessionUserInfo.value)
      .then(() =>  window.location.reload())
      .catch(() => ksToast.error(t('error')))
  }
}

const saveThenClose = () => {
  if (userGrades.value.length) {
    saveLanguage()
    emit('close')
  }
  else ksToast.info(t('settings.chooseGradeToClose'))
}

watch(props, () => {
  if (props.open) {
    savedPreferredLanguage = userPreferredLanguage.value
  }
  if(!props.open) {
    Object.keys(settings).forEach(key => settings[key] = false)
  }
})
</script>

<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDrawer
      :title="t('settings.title')"
      :open="props.open"
      size="600px"
      @close="saveThenClose"
    >
      <template #body>
        <div class="flex flex-col gap-6 pb-16">
          <SettingsBasic />
          <KsCallout
            v-if="readOnlyGradesSpansAcrossgradeTypes"
            :title="t('settings.readOnlyGradesSpansAcrossgradeTypes.title')"
            variant="info"
          >
            <p v-text="t('settings.readOnlyGradesSpansAcrossgradeTypes.info')" />
          </KsCallout>
          <fieldset>
            <legend
              class="mb-2 text-base font-medium uppercase tracking-wide text-gray-40"
              v-text="t('settings.sections.gradeType')"
            />
            <div class="flex flex-wrap gap-4">
              <GradeTypeButton
                v-for="gradeType in gradeTypes"
                :key="gradeType"
                :grade-type="gradeType"
                :disabled="isLoading"
                @save-grade-type="setGradeType(gradeType)"
              />
            </div>
          </fieldset>
          <hr
            class="border-t border-gray-10"
            aria-hidden="true"
          >
          <fieldset class="flex gap-3">
            <legend
              class="mb-2 text-base font-medium uppercase tracking-wide text-gray-40"
              v-text="t('settings.sections.language')"
            />
            <KsButton
              variant="secondary"
              shape="rounded"
              type="label"
            >
              <KsInput
                :value="PreferredLanguage.Bokmal"
                type="radio"
                :checked="isPreferredLanguage(PreferredLanguage.Bokmal)"
                :disabled="isLoading"
                @click="setPreferredLanguage(PreferredLanguage.Bokmal)"
              />
              {{ t(`metadata.language.${PreferredLanguage.Bokmal}`) }}
            </KsButton>
            <KsButton
              variant="secondary"
              shape="rounded"
              type="label"
            >
              <KsInput
                :value="PreferredLanguage.Nynorsk"
                type="radio"
                :checked="isPreferredLanguage(PreferredLanguage.Nynorsk)"
                :disabled="isLoading"
                @click="setPreferredLanguage(PreferredLanguage.Nynorsk)"
              />
              {{ t(`metadata.language.${PreferredLanguage.Nynorsk}`) }}
            </KsButton>
          </fieldset>
          <hr
            class="border-t border-gray-10"
            aria-hidden="true"
          >
          <div v-if="isTeacher">
            <p
              class="mb-2 text-base font-medium uppercase tracking-wide text-gray-40"
              v-text="t('settings.sections.instruction')"
            />
            <div class="flex flex-col gap-3">
              <KsButton
                class="justify-between"
                variant="secondary"
                icon-right="long-arrow-right"
                :disabled="!hasOrganization"
                @click="settings.groups = true"
              >
                {{ t('settings.drawers.groups') }}
              </KsButton>
              <KsButton
                class="justify-between"
                variant="secondary"
                icon-right="long-arrow-right"
                @click="settings.plans = true"
              >
                {{ t('settings.drawers.plans') }}
              </KsButton>
              <KsButton
                class="justify-between"
                variant="secondary"
                icon-right="long-arrow-right"
                @click="settings.licenses = true"
              >
                {{ t('settings.drawers.licenses') }}
              </KsButton>
            </div>
          </div>
          <hr
            v-if="isTeacher"
            class="border-t border-gray-10"
            aria-hidden="true"
          >
          <div>
            <p
              class="mb-2 text-base font-medium uppercase tracking-wide text-gray-40"
              v-text="t('settings.drawers.shortcuts')"
            />
            <div class="flex flex-col gap-3">
              <KsButton
                class="justify-between"
                variant="secondary"
                icon-right="long-arrow-right"
                :href="digitalBooksUrl"
              >
                {{ t('settings.shortcuts.digitalBooks') }}
              </KsButton>
              <KsButton
                class="justify-between"
                variant="secondary"
                icon-right="long-arrow-right"
                @click="router.push('/nyheter').then(() => emit('close'))"
              >
                {{ t('blog.title') }}
              </KsButton>
              <KsButton
                class="justify-between"
                variant="secondary"
                icon-right="long-arrow-right"
                :href="oldContentUrl"
              >
                {{ t('settings.shortcuts.oldContent') }}
              </KsButton>
            </div>
          </div>
        </div>
      </template>
    </KsDrawer>
  </Teleport>
  <SettingsGroups
    :open="settings.groups"
    @close="emit('close')"
    @back="settings.groups = false"
  />
  <SettingsLicenses
    :open="settings.licenses"
    @close="emit('close')"
    @back="settings.licenses = false"
  />
  <SettingsYearPlans
    :open="settings.plans"
    @close="emit('close')"
    @back="settings.plans = false"
  />
</template>
