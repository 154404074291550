<script setup lang="ts">
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsCallout, KsDrawer, KsExpand, KsExpandItem, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import useLicenseStore from '~/stores/license'
import { TeleportationTarget } from '~/models/TeleportationTarget'
import useTime from '~/composables/useTime'

const { t } = useI18n()

const {
  isoStringToDateTime,
  dateTimeToStandardOutputFormat,
} = useTime()

const {
  loadLicenses,
  $reset,
} = useLicenseStore()

const {
  licenses,
  isLoadingLicenses,
  hasLoadedLicenses,
  hasLicenses,
} = storeToRefs(useLicenseStore())

const props = defineProps<{
  open: boolean
}>()

const emit = defineEmits(['back', 'close'])

watch(() => props.open, async (open) => {
  if (open) {
    await loadLicenses()
  }
  if (!open) {
    $reset()
  }
})

const getNumberOfSkeletons = computed(() => {
  return props.open ? Math.floor(Math.random() * 15) + 3 : 0
})
</script>

<template>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDrawer
      :title="t('settings.drawers.licenses')"
      :open="props.open"
      :backdrop="false"
      size="600px"
      @close="emit('close')"
    >
      <template #header>
        <KsButton
          shape="square"
          icon-left="arrow-left"
          :aria-label="t('back')"
          :title="t('back')"
          @click="emit('back')"
        />
      </template>
      <template #body>
        <div class="flex flex-col gap-4 border-t border-gray-10 py-6">
          <div>
            <KsSkeletonWrapper v-if="isLoadingLicenses">
              <KsSkeleton
                v-for="index in getNumberOfSkeletons"
                :key="index"
                height="2.2rem"
              />
            </KsSkeletonWrapper>

            <KsCallout
              v-if="hasLoadedLicenses && !hasLicenses"
              variant="info"
              :title="t('license.noLicenses.title')"
            >
              {{ t('license.noLicenses.message') }}
            </KsCallout>
            <KsExpand
              v-if="hasLicenses"
              size="small"
            >
              <KsExpandItem
                v-for="license in licenses"
                :key="license.ean"
                :title="t('license.message', { name: license.name })"
              >
                <template #content>
                  <table class="flex flex-col gap-3 text-left text-base">
                    <caption
                      class="sr-only"
                      v-text="t('license.message', { name: license.name })"
                    />
                    <tbody>
                      <tr class="flex">
                        <th
                          class="basis-1/5"
                          v-text="t('license.ean')"
                        />
                        <td
                          class="grow"
                          v-text="license.ean"
                        />
                      </tr>
                      <tr class="flex">
                        <th
                          class="basis-1/5"
                          v-text="t('license.licenseModel')"
                        />
                        <td
                          class="grow"
                          v-text="t(`licenseModel.${license.licenseModel}`)"
                        />
                      </tr>
                      <tr class="flex">
                        <th
                          class="basis-1/5"
                          v-text="t('license.endDate')"
                        />
                        <td
                          class="grow"
                          v-text="dateTimeToStandardOutputFormat(isoStringToDateTime(license.endDate ?? ''))"
                        />
                      </tr>
                      <tr class="flex">
                        <th
                          class="basis-1/5"
                          v-text="t('license.orderId')"
                        />
                        <td
                          class="grow"
                          v-text="license.orderId"
                        />
                      </tr>
                      <tr class="flex">
                        <th
                          class="basis-1/5"
                          v-text="t('license.usageRights')"
                        />
                        <td
                          class="grow"
                          v-text="license.usageRights"
                        />
                      </tr>
                    </tbody>
                  </table>
                </template>
              </KsExpandItem>
            </KsExpand>
          </div>
        </div>
      </template>
    </KsDrawer>
  </Teleport>
</template>
