<script setup lang="ts">
import { ref } from 'vue'
import { KsButton, KsIcon, KsInput } from '@aschehoug/kloss'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import DefaultMessages from '~/components/home/DefaultMessages.vue'

const router = useRouter()
const { t } = useI18n()

const searchQuery = ref('')

const doSearch = () => {
  if (searchQuery.value.length > 2)
    router.push({ name: 'search', query: { fullText: searchQuery.value } })
  return
}
</script>

<template>
  <section class="bg-green-5">
    <div class="mx-auto max-w-screen-au px-4 py-20 sm:px-8">
      <DefaultMessages />
      <h2 class="mb-5 font-extrabold fluid-text-xl">
        {{ t('search.globalSearch') }}
      </h2>
      <form
        role="search"
        class="relative mb-6 rounded-full border-green-20 md:w-1/2"
        @submit.prevent="doSearch"
      >
        <KsIcon
          icon="magnifying-glass"
          class="absolute left-7 top-1/2 z-10 -translate-y-1/2 text-xl"
        />
        <KsInput
          ref="searchInput"
          v-model="searchQuery"
          type="text"
          maxlength="200"
          name="search"
          shape="rounded"
          class="!bg-white !pl-16 !text-xl placeholder:text-lg placeholder:text-gray-50"
          :placeholder="t('search.placeholder')"
        />
        <KsButton
          class="absolute right-[2px] top-[1px]"
          variant="primary"
          size="large"
          shape="round"
          icon-left="arrow-right"
          :aria-label="t('search.title')"
          :disabled="searchQuery.length < 3"
          @click.prevent="doSearch"
        />
      </form>
    </div>
  </section>
</template>
