<script setup lang="ts">
import type { Product } from '~/models/Product'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import useProductStore from '~/stores/product'
import useText from '~/composables/useText'
import useSubjectButton from '~/composables/useSubjectButton'
import useSlugify from '~/composables/useSlugify'
import SupportProductModal from '~/components/home/SupportProductModal.vue'

const { t } = useI18n()
const router = useRouter()
const { slugify } = useSlugify()
const { capitalize } = useText()
const { getStyles } = useSubjectButton()

const {
  filteredSubjectCodes,
  selectedSupportProductName,
  filteredAddonProducts,
  filteredSupportProductNames,
  filteredSupportProducts,
} = storeToRefs(useProductStore())

const buildProductUrl = (product: Product) => router.resolve({
  name: 'product',
  params: {
    locationId: product.aunivers.locationId,
    slug: slugify(product.aunivers.name),
  },
}).href
</script>

<template>
  <ul
    v-if="filteredSubjectCodes.length || filteredAddonProducts.length || filteredSupportProductNames.length "
    class="flex flex-col gap-2 sm:flex-row sm:flex-wrap"
  >
    <li
      v-for="(subject, index) of filteredSubjectCodes"
      :key="subject"
    >
      <RouterLink
        :to="{ name: 'subject', params: { subject: subject.toLowerCase() } }"
        class="inline-flex w-full rounded-xl transition focus-visible:ring"
      >
        <KsButton
          variant="primary"
          type="label"
          stretch
          center
          class="subject-button !text-2xl !font-bold"
          :style="getStyles(index)"
        >
          {{ t(`metadata.subjects.${subject}`) }}
        </KsButton>
      </RouterLink>
    </li>
    <li
      v-for="(addon, index) in filteredAddonProducts"
      :key="addon.ean"
    >
      <RouterLink
        :to="{ path: buildProductUrl(addon) }"
        class="inline-flex w-full rounded-xl transition focus-visible:ring"
      >
        <KsButton
          variant="primary"
          type="label"
          stretch
          center
          class="subject-button !text-2xl !font-bold"
          :style="getStyles(filteredSubjectCodes.length + index)"
        >
          {{ addon.aunivers.name }}
        </KsButton>
      </RouterLink>
    </li>
    <li
      v-for="(name, index) of filteredSupportProductNames"
      :key="name"
    >
      <KsButton
        variant="primary"
        stretch
        center
        class="subject-button relative !text-2xl !font-bold after:absolute after:bottom-0 after:right-0 after:box-content after:h-[--flip-size] after:w-[--flip-size] after:rounded-tl-[inherit] after:border-l-2 after:border-t-2 after:border-[#070707] after:bg-[--ks-secondary]"
        :style="{
          ...getStyles(filteredSubjectCodes.length + filteredAddonProducts.length + index),
          '--flip-size': '1.5rem',
          'clip-path': 'polygon(0 0, 100% 0, 100% calc(100% - var(--flip-size)), calc(100% - var(--flip-size)) 100%, 0 100%)',
        }"
        @click="selectedSupportProductName = name"
      >
        {{ capitalize(name) }}
      </KsButton>

      <SupportProductModal
        :name="name"
        :products="filteredSupportProducts"
        :subjects="filteredSubjectCodes"
        @close="selectedSupportProductName = undefined"
      />
    </li>
  </ul>

  <KsSkeletonWrapper
    v-else
    class="flex flex-col gap-2 sm:flex-row sm:flex-wrap"
  >
    <KsSkeleton
      v-for="n in 9"
      :key="n"
      :width="`${n % 4 * 10 + 10}ch`"
      height="3rem"
    />
  </KsSkeletonWrapper>
</template>

<style scoped>
.subject-button {
  --ks-primary: #002422;
  padding: 1.2em 1em;
}
.subject-button:hover {
  --ks-primarytext: #002422!important;
}
</style>
