import type { Ref } from 'vue'
import type { Criterion } from '~/models/Search'
import type { SearchResponse } from '~/models/Content/Response'
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { waitFor } from '~/utils/asyncUtils'
import useProductStore from '~/stores/product'
import useSearchHelper from '~/composables/useSearchHelper'
import { useSearch } from '~/composables/useSearch'
import usePendo from '~/composables/usePendo'
import useContentMapper from '~/composables/useContentMapper'
import useAggregationMapper from '~/composables/useAggregationMapper'

export default function useSearchLogic(
  searchQuery: Ref<string>,
  page: Ref<number>,
  pageLimit: number,
  spellcheck: boolean,
  getCriterion: () => Criterion = () => ({}),
  pendoTrack: Function,
  pendoTrackName: string,
  pendoTrackParams: Record<string, any> = {})
{

  const FUZZINESS = 1.0
  const BOOST_FIELDS = {
    'search_keywords': 20,
    'title': 10,
    'm_intro': 4,
    'intro': 2,
  }

  const { pendoFlushNow } = usePendo()
  const { mapTermAggregations, mapStateAggregations } = useAggregationMapper()
  const { isLoading: isLoadingProducts, hasLoaded: hasLoadedProducts, matchingProducts } = storeToRefs(useProductStore())
  const { searchPath, cardFields, defaultContentTypes } = useSearchHelper()
  const { mapResult, mapContents } = useContentMapper()
  const {
    fetchResults,
    results: response,
    isLoading: isLoadingResults,
    hasFailed
  } = useSearch<SearchResponse>(
    searchPath,
    { transformData: (response: SearchResponse) => response }
  )

  const isTyping = ref(false)

  const results = computed(() => response.value ? mapResult(response.value) : undefined)
  const items = computed(() => results.value ? mapContents(results.value) : [])
  const totalCount = computed(() => results.value?.count || 0)
  const totalPages = computed(() => Math.ceil(totalCount.value / pageLimit))
  const offset = computed(() => (page.value - 1) * pageLimit)
  const isLoading = computed(() => isTyping.value || isLoadingResults.value || isLoadingProducts.value)
  const noResults = computed(() => !isLoading.value && !items.value.length)
  const labelAggregations = computed(() => mapTermAggregations('labels', results.value?.aggregations || []))
  const gradeAggregations = computed(() => mapTermAggregations('grades',results.value?.aggregations || []))
  const activityAggregations = computed(() => mapTermAggregations('activities',results.value?.aggregations || []))
  const accessAggregations = computed(() => mapStateAggregations('access', results.value?.aggregations || []))

  const spellingIncorrect = computed(() => !!response.value?.Spellcheck?.Incorrect)
  const spellingSuggestion = computed(() => response.value?.Spellcheck?.Suggestion ?? '')

  const resetResults = () => {
    if (!results.value) return
    results.value.searchHits.searchHit = []
    results.value.aggregations = []
    results.value.count = 0
  }

  const doSearch = async () => {
    await waitFor(() => hasLoadedProducts.value, 10000)
    const criterion = getCriterion()
    await fetchResults({
      text: searchQuery.value,
      fuzziness: FUZZINESS,
      spellcheck: spellcheck,
      fields: cardFields,
      boost: BOOST_FIELDS,
    }, {
      contentTypeCriterion: defaultContentTypes,
      mainLocationCriterion: true,
      ...criterion
    }, pageLimit, offset.value)
    pendoTrack(pendoTrackName, {
      text: searchQuery.value || '',
      results: results.value?.count || 0,
      ...pendoTrackParams,
    })
    pendoFlushNow()
  }

  return {
    isTyping,
    items,
    totalCount,
    totalPages,
    offset,
    matchingProducts,
    isLoading,
    noResults,
    hasFailed,
    labelAggregations,
    gradeAggregations,
    activityAggregations,
    accessAggregations,
    doSearch,
    resetResults,
    spellingIncorrect,
    spellingSuggestion,
  }
}
