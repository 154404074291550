<script setup lang="ts">
import type { ContentResource } from '~/models/Content/ContentResource'
import { PendoEventName, PendoTrackName } from '~/models/Pendo'
import { LoginName, LoginSource } from '~/models/Login'
import { onMounted, ref } from 'vue'
import { KsButton, KsCallout, KsSkeleton } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { setTitle } from '~/utils/dom'
import { waitFor } from '~/utils/asyncUtils'
import { useMessagesStore } from '~/stores/messages'
import { useResourceApi } from '~/api/resourceApi'
import useLogin from '~/composables/useLogin'
import CardSkeleton from '~/components/skeletons/CardSkeleton.vue'
import ArticleCard from '~/components/cards/ArticleCard.vue'
import FlowHeaderBorder from '~/components/login/FlowHeaderBorder.vue'
import usePendo from '~/composables/usePendo'
import AuLogo from '~/components/utils/AuLogo.vue'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'

const { t } = useI18n()
const { hasLoginMessage, getMessages, messages } = useMessagesStore()
const { getLoginPageResources } = useResourceApi()
const { createLoginUrl } = useLogin()
const { pendoEnabled, initAnonPendo, pendoTrack, pendoTeardown } = usePendo()

const articles = ref<ContentResource[]>([])
const isLoading = ref(false)
const activeButton = ref('')
const hasTrackedLogin = ref(false)

const getArticles = async () => {
  try {
    isLoading.value = true
    articles.value = await getLoginPageResources()
  } catch (error) {
    articles.value = []
    throw error
  } finally {
    isLoading.value = false
  }
}

/**
 * Catch the first click on a login button, initialize Pendo, track, teardown and _then_ redirect
 * Maximum allowed delay is 2 seconds, after which the user is redirected without tracking
 */
const loginButtonClick = async (type: string, redirect: string, event: PointerEvent) => {
  if (!pendoEnabled) {
    window.location.href = redirect
    return
  }

  if (event.isTrusted) {
    activeButton.value = type
    window.addEventListener(PendoEventName.Ready, pendoTrackHandler)
    initAnonPendo()
  }

  await waitFor(() => hasTrackedLogin.value, 2000)

  activeButton.value = ''
  window.removeEventListener(PendoEventName.Ready, pendoTrackHandler)
  window.location.href = redirect
}

const pendoTrackHandler = () => {
  pendoTrack(PendoTrackName.LoginButton, {
    type: activeButton.value,
    app: 'AU2',
  })
  pendoTeardown()
  hasTrackedLogin.value = true
}

onMounted( () => {
  getMessages()
  getArticles()
})
setTitle(t('login.title'))
</script>

<template>
  <h1
    class="sr-only"
    v-text="t('login.brand')"
  />
  <div class="flex w-full flex-col items-center bg-[#165851]">
    <AuLogo class="mx-auto h-24 w-auto pt-8 text-white" />
    <img
      src="../assets/images/Aunivers.png"
      class="mx-auto w-[400px] pb-16 sm:w-[600px] sm:pb-0"
      alt=""
    >
  </div>
  <div class="relative -mt-1">
    <FlowHeaderBorder />
    <div class="absolute -top-20 mb-12 flex w-full flex-col sm:absolute sm:-top-6 xl:top-0">
      <p
        class="z-20 mb-3 text-center font-bold text-white fluid-text-lg"
        v-text="t('login.title')"
      />
      <div class="z-20 flex justify-center gap-4">
        <KsButton
          :href="createLoginUrl(LoginSource.Feide)"
          variant="primary"
          shape="rounded"
          @click.once.prevent="loginButtonClick(LoginName.Feide, createLoginUrl(LoginSource.Feide), $event)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            class="size-5"
          >
            <path
              fill="currentColor"
              d="M16.61 10.346h-1.739v4.175h1.74v-4.175ZM10.87 11.563H9.13v4.697H5.129v-5.914h-1.74v6.262h.009V18H16.61v-1.74h-5.74v-4.697ZM10 9.824a1.044 1.044 0 1 0 0-2.088 1.044 1.044 0 0 0 0 2.088Z"
            />
            <path
              fill="currentColor"
              d="M10 3.74a4.872 4.872 0 0 1 4.866 4.866h1.74A6.613 6.613 0 0 0 10 2a6.613 6.613 0 0 0-6.606 6.606h1.741A4.87 4.87 0 0 1 10 3.74Z"
            />
          </svg>
          {{ t('login.withServiceProvider') }}
        </KsButton>
        <KsButton
          :href="createLoginUrl(LoginSource.Local)"
          variant="secondary"
          shape="rounded"
          @click.once.prevent="loginButtonClick(LoginName.Local, createLoginUrl(LoginSource.Local), $event)"
        >
          {{ t('login.withLocalProvider') }}
        </KsButton>
      </div>
    </div>
  </div>
  <div class="mx-auto max-w-screen-au px-4 pt-8 sm:px-8">
    <KsCallout
      v-if="hasLoginMessage"
      :title="messages?.loginMessageTitle"
      variant="warning"
      class="z-10 mx-auto mb-8 max-w-2xl"
    >
      <RichTextRenderer :text="messages?.loginMessage" />
    </KsCallout>
    <div
      v-if="isLoading"
      class="text-center"
    >
      <KsSkeleton
        width="120px"
        height="25px"
        class="mb-6"
      />
      <div class="grid grid-cols-1 gap-6 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <CardSkeleton
          v-for="i in 4"
          :key="i"
          height="370px"
        />
      </div>
    </div>
    <div v-else>
      <h2
        v-if="articles.length"
        class="mb-6 text-center font-bold fluid-text-lg"
        v-text="t('login.news')"
      />
      <ul
        v-if="articles.length"
        class="grid grid-cols-1 gap-6 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
      >
        <li
          v-for="article in articles"
          :key="article.contentId"
        >
          <ArticleCard :article="article" />
        </li>
      </ul>
    </div>
  </div>
</template>
