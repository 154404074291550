<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useContentHelper } from '~/composables/useContentHelper'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useDescriptor from '~/composables/useDescriptor'
import CardMenu from '~/components/cards/buttons/CardMenu.vue'
import useColors from '~/composables/colors'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'

const props = withDefaults(defineProps<{
  resource: BaseItem
  loading?: 'eager' | 'lazy'
}>(), {
  loading: 'lazy'
})

const { getTitle } = useContentHelper()
const { buildResourceUrl } = useUrlBuilder()
const { getDescriptor } = useDescriptor()
const { t } = useI18n()

const elementRef = ref()

const { backgroundStyle } = useColors({
  identifier: props.resource.locationId!,
  elementRef,
})

const title = computed(() => getTitle(props.resource))
const link = computed(() => buildResourceUrl(props.resource))
const label = getDescriptor(props.resource)
</script>

<template>
  <article
    ref="elementRef"
    class="group relative flex h-[280px] animate-fade flex-col justify-between space-y-2 rounded-xl p-4 hover:shadow-2xl"
    :style="backgroundStyle"
  >
    <aside
      v-if="resource.new"
      class="pointer-events-none absolute right-0 top-0 z-20 rounded-bl-2xl bg-green-40 p-1.5 pl-2 text-sm font-medium uppercase tracking-wider text-white"
      v-text="t('newShort')"
    />
    <span
      class="self-start rounded-md bg-green-60 px-2 py-1 text-xs font-medium text-white"
      v-text="label"
    />
    <h3 class="text-xl font-medium text-black">
      <LinkOrAnchor
        class="line-clamp-3 outline-0 before:absolute before:inset-0 before:rounded-2xl before:transition before:hover:shadow-lg before:focus-visible:ring"
        :link="link"
      >
        {{ title }}
      </LinkOrAnchor>
    </h3>
    <div
      v-if="resource.thumbnail"
      class="pointer-events-none aspect-video overflow-hidden rounded-xl"
    >
      <img
        class="h-full w-full rounded-xl object-cover transition duration-200 group-hover:scale-110"
        :src="resource.thumbnail"
        :alt="resource.thumbnailAlt || ''"
        :loading
      >
    </div>
    <CardMenu
      button-variant="tertiary"
      :resource="resource"
    />
  </article>
</template>
